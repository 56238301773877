<template>
  <CustomModal ref="core">

    <div class="tav-modal d-flex flex-column" v-if="stateStep === 'email'">
      <img @click="close" src="@/assets/img/Modal/Close.svg" alt="">
      <h4 class="title">Forgot password</h4>
      <p class="description">Enter your email and we will send you restoring code</p>
      <input class="fname"
             placeholder="Email *"
             v-model="$v.email.$model"
             type="text"
             :class="{'error':$v.email.$error}"
      >
      <div class="btn-container d-flex flex-row justify-between align-end">
        <button @click="close" class="btn open">Cancel</button>
        <button class="btn close" @click="recordingTav('code')">Continue</button>
      </div>
    </div>

    <div class="tav-modal d-flex flex-column" v-if="stateStep === 'code'">
      <img @click="close()" src="@/assets/img/Modal/Close.svg" alt="">
      <h4 class="title">Forgot password</h4>
      <p class="description">We sent you a verification code by email. Please enter the code below</p>
      <input class="fname"
             placeholder="Enter code *"
             v-model="$v.code.$model"
             type="text">
      <p class="receive">Did not receive the code? <span class="resend" @click="recordingTav('code')">Resend</span></p>
      <div class="btn-container d-flex flex-row justify-between align-end">
        <button @click="close()" class="btn open">Cancel</button>
        <button class="btn close" @click="recordingTav('password')">Continue</button>
      </div>
    </div>

    <div class="tav-modal d-flex flex-column" v-if="stateStep === 'password'">
      <img @click="close()" src="@/assets/img/Modal/Close.svg" alt="">
      <h4 class="title">Forgot password</h4>
      <p class="description">Enter your new password and confirm it</p>
      <input class="password"
             placeholder="Password *"
             v-model="$v.password.$model"
             :class="{'error':$v.password.$error}"
             type="password">
      <input class="repeat-password"
             placeholder="Confirm password *"
             v-model="$v.repeatPassword.$model"
             :class="{'error':$v.repeatPassword.$error}"
             type="password">
      <div class="btn-container d-flex flex-row justify-between align-end">
        <button @click="close()" class="btn open">Cancel</button>
        <button class="btn close" @click="close">Continue</button>
      </div>
    </div>

  </CustomModal>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {required,email,minLength,sameAs} from 'vuelidate/lib/validators';

export default {
  name: "ForgotPasswordEmail",
  mixins:[validationMixin],
  data(){
    return{
      email:'',
      code:'',
      password:'',
      repeatPassword:'',
      stateStep:'email',
      token:''
    }
  },
  components:{
    CustomModal: () => import('@/components/Modals/CustomModal/CustomModal.vue'),
  },
  validations:{
    email:{
      required,
      email,
    },
    code:{
      required
    },
    password:{
      required,
      minLength:minLength(8)
    },
    repeatPassword:{
      required,
      sameAsPassword:sameAs('password')
    }
  },
  methods:{
    close(){
      this.stateStep = 'email'
      this.$refs.core.closeModal();
    },
    open(){
      this.$refs.core.openModal();
    },
    recordingTav(step){
      if(this.stateStep === 'email'){
        this.$v.$touch()
        if (this.$v.$invalid.email) {
          console.log('error')
        } else {
          this.$store.dispatch('sendPasswordResetKey',this.email)
          .then(response => {
            if(response !== undefined){
              this.stateStep = step
            }
          })
        }
      }else if(this.stateStep === 'code'){
        this.$v.$touch()
        if(this.$v.$invalid.code){
          console.log('error')
        }else {
          this.$store.dispatch('verifyPasswordResetKey',{email:this.email,code:this.code})
          .then(res => {
            if (res) {
              this.token = res
              this.stateStep = step
            }
          })

        }
      }else if(this.stateStep === 'password'){
        if(this.$v.$invalid.password){
         console.log('error')
        }else {
          this.$store.dispatch('resetPassword',{token:this.token.value,newPassword:this.password})
          this.close()
        }
      }
    },
  },
}
//TODO нужно настроить vuelidate, что бы он смотрел на ошибки с полей
//TODO уточнить валидацию пароля
</script>

<style scoped lang="scss">
@import "../ForgotPassword";
</style>